import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import Heading from '@interness/web-core/src/components/text/Heading/Heading';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <hr></hr>
    <Heading subtitle="Verantwortung für unsere Umwelt und Gesellschaft" mdxType="Heading">Nachhaltigkeit bei Ihrem Juwelier Cladder</Heading>
    <Wrapper width={1024} mdxType="Wrapper">
      <p>{`  Als Ihr ortsansässiger Juwelier legen wir großen Wert auf Nachhaltigkeit.
Wir setzen uns aktiv dafür ein, dass unsere Branche einen positiven Einfluss auf die Umwelt und die Gesellschaft hat.
Wir sind uns bewusst, dass die Gewinnung von Rohmaterialien und die Produktion unserer Ware mit ökologischen
und sozialen Herausforderungen verbunden sind, und möchten unseren Teil dazu beitragen, diese Herausforderungen
anzugehen.`}</p>
      <p>{`  In unserem Geschäftsbetrieb ergreifen wir Maßnahmen, um ressourcenschonend und umweltfreundlich zu handeln.
Wir setzen auf energieeffiziente Beleuchtungssysteme, recyceln Verpackungsmaterialien und bemühen uns um eine
Reduzierung des Einsatzes von Einwegprodukten.`}</p>
      <p>{`  Als Ihr nachhaltig orientierter Juwelier bieten wir Ihnen deshalb`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`Trauringe und Schmuck aus recycelten Edelmetallen`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Edelsteine aus zertifizierter Gewinnung`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Uhren aus nachhaltiger Produktion`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Verpackungen aus recyceltem Material`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Recycling von Edelmetallen`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Recycling von Batterien`}</p>
          <p parentName="li">{`Wir möchten unsere Kunden dazu ermutigen, bewusste Entscheidungen zu treffen und sich für nachhaltig hergestellten
Schmuck und Uhren zu entscheiden. In dem Sie bei uns einkaufen, unterstützen Sie unsere Bemühungen für eine
nachhaltigere und umweltschonendere Schmuck- und Uhrenproduktion. Sie tragen damit bei, positive Veränderungen zu
bewirken.`}</p>
          <p parentName="li">{`Der Erhalt bestehender Werte schont die Umwelt und mindert den Ressourcenverbrauch.
Deshalb erhalten Sie Ihre Werte. Gerne unterstützen wir Sie dabei in unseren zertifizierten Uhrmachermeister- und
Goldschmiedemeisterwerkstätten.`}</p>
          <p parentName="li">{`Nachhaltigkeit ist für uns mehr als nur ein Trend – es ist eine Verpflichtung, die wir jeden Tag leben.
Wir laden Sie ein, Teil dieser Bewegung zu werden und mit uns gemeinsam eine bessere Zukunft für unsere Erde und
kommende Generationen zu gestalten.`}</p>
          <p parentName="li">{`Nachhaltigkeit macht glücklich und zufrieden!`}</p>
          <hr parentName="li"></hr>
        </li>
      </ul>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      